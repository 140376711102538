import React from 'react'
import styles from './Error404.module.css'
import Link from 'gatsby-link'

export default () => (
  <div className={ styles.error404Container }>
    <h1 className={ styles.heading }>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    <Link to='/'>
      <button className={ styles.backButton } type="button">
        Back to home page
      </button>
    </Link>
  </div>
)
