import React from 'react'
import Layout from '../components/layout'
import Error404 from '../components/Error404'

const NotFoundPage = () => (
  <Layout>
    <Error404 />
  </Layout>
)

export default NotFoundPage
